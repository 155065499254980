import { IonInput, IonText, IonSelect, IonSelectOption, IonButton } from "@ionic/react";
import { FormEvent, useEffect } from "react";
import { useRef } from "react";
import {
  ONE_MINUTE,
  ONE_DAY,
  ONE_HOUR,
  ONE_WEEK,
  ONE_YEAR,
  decomposedCooldown
} from '@utils/date';
import { Item } from "@definitions/items";
import { useItems } from "@hooks/ItemProvider";

type Props = {
  sentenceBreaker: string
  actionName: string
  submitAction: (item:Item) => void
  showLabels: boolean
  formRef?: React.RefObject<HTMLFormElement>
  id?: string
  taskPlaceholder?: string
  className?: string
}

const ItemForm = ({ sentenceBreaker, showLabels, actionName, submitAction, formRef, id, taskPlaceholder, className }:Props) => {
  const { items } = useItems();
  const currentItem = id ? items[id] : null;

  const amountRef = useRef<HTMLIonInputElement>(null);
  const unitRef = useRef<HTMLIonSelectElement>(null);

  const initialValues = () => {
    if(!currentItem) {
      return {
        name: "",
        amount: null,
        unit: ONE_DAY,
      }
    }
    return {
      name: currentItem.name,
      ...decomposedCooldown(currentItem.cooldown),
    }
  }

  const calculatedCooldown = () => {
    const multiplier = unitRef.current?.value;
    const amount = parseFloat(amountRef.current?.value?.toString() || "0");
    return (multiplier * amount);
  }

  const onSubmit = (e:FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const cooldown = calculatedCooldown()
    const dataObject = new FormData(e.target as HTMLFormElement);
    const item:Item = {
      name: dataObject.get('name') as string,
      cooldown: cooldown,
      lastClicked: currentItem?.lastClicked || Date.now(),
      updatedAt: Date.now(),
    }

    submitAction(item)
  };

  return (
    <form className={className || "item-form"} onSubmit={onSubmit} ref={formRef}>
      <IonInput
        label={ showLabels ? "Name" : undefined }
        labelPlacement="stacked"
        fill={ showLabels ? "outline" : undefined }
        placeholder={taskPlaceholder || "A task"}
        name="name"
        type="text"
        minlength={1}
        maxlength={255}
        required={true}
        value={initialValues().name}
      />
      <IonText>{sentenceBreaker}</IonText>
      <div className="row">
        <IonInput
          labelPlacement="stacked"
          label={showLabels ? "Cooldown amount" : undefined}
          fill={showLabels ? "outline" : undefined}
          aria-label="cooldown-amount"
          name="cooldown-amount"
          placeholder="1"
          min="0.5"
          step="0.5"
          ref={amountRef}
          type="number"
          required={true}
          value={initialValues().amount}
        />
        <IonSelect
          labelPlacement="stacked"
          label={showLabels ? "Cooldown unit" : undefined}
          fill={showLabels ? "outline" : undefined}
          aria-label="cooldown-units"
          interface="popover"
          name="cooldown-unit"
          ref={unitRef}
          value={initialValues().unit}
        >
          <IonSelectOption value={ONE_MINUTE}>minutes</IonSelectOption>
          <IonSelectOption value={ONE_HOUR}>hours</IonSelectOption>
          <IonSelectOption value={ONE_DAY}>days</IonSelectOption>
          <IonSelectOption value={ONE_WEEK}>weeks</IonSelectOption>
          <IonSelectOption value={ONE_YEAR}>years</IonSelectOption>
        </IonSelect>
      </div>
      <IonButton type="submit">{ actionName }</IonButton>
    </form>
  );
}

export default ItemForm;
