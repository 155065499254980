import { UI } from "@definitions/ui";
import { createContext, useContext, useState } from "react";

type UIContextType = {
  ui: UI,
  setUI: (ui:UI) => void,
}

const defaultUIState = ():UI => ({
  editingID: null,
})

const UIContext = createContext({
  ui: defaultUIState(),
  setUI: (ui:UI) => {}
});

export const useUI = ():UIContextType => {
  const { ui, setUI } = useContext(UIContext);

  return { ui, setUI };
}

const UIProvider = ({ children }: any) => {
  const [ ui, setUI ] = useState<UI>(defaultUIState());

  return (
    <UIContext.Provider value={{ ui, setUI }}>
      {children}
    </UIContext.Provider>
  );
}

export default UIProvider;