import { IonFooter } from "@ionic/react";

const Footer = () => {
  return (
    <IonFooter>
      <footer>
        <a href="https://forms.gle/hWTCY1XK9heFS2y88">Want to give feedback?</a>
      </footer>
    </IonFooter>
  );
}

export default Footer;