import { APIResponse } from "@definitions/api";

export const baseURL = `${process.env.BACKEND_HOST || "http://localhost:3000"}/api/v1`;

export const baseHeaders = { "Content-Type": "application/json", "Referrer-policy": "origin" };

export const buildResponse = ({ success, errors, data }:{ success?:boolean, errors?:object, data?: object }):APIResponse => {
  return {
    success: success || false,
    payload: {
      errors: errors || {},
      data: data || {}
    }
  }
}