import { mergeWith } from "lodash";
import { Item, Items } from "@definitions/items";

export const updateItem = (items:Items, id:string, payload:Item) => {
  return { ...items, [id]: payload }
};

export const resetClickTime = (items:Items, id:string) => {
  return { ...items, [id]: { ...items[id], lastClicked: Date.now(), updatedAt: Date.now() } }
};

export const mergeItems = (localItems:Items, remoteItems:Items) => {
  return mergeWith(localItems, remoteItems, (localItem:Item, remoteItem:Item, key:string) => {
    if(!localItem) { return remoteItem }
    if(!remoteItem) { return localItem }

    if (remoteItem.updatedAt > localItem.updatedAt) { return remoteItem; }

    return localItem;
  });
};