import { Item } from "@definitions/items";
import { useItems } from "@hooks/ItemProvider";
import { IonButton, IonContent, IonIcon, IonPopover } from "@ionic/react";
import { calculatedColour, contrastColour, humanisedDateDifference, TERMINAL_HUE } from "@utils/date";
import { resetClickTime } from "@utils/state";
import { MouseEvent, useEffect, useState } from "react";
import { useUI } from "@hooks/UIProvider";

type Props = {
  item: Item
  id: string
}

const ItemLeaf = ({ item, id }:Props) => {
  const [ now, setNow ] = useState(Date.now())
  const [ heck, setHeck ] = useState(false)
  const [ heckstring, setHeckstring ] = useState("heck")
  const { items, setItems } = useItems();
  const { setUI } = useUI();
  const [ interval, setIntervalRef ] = useState<NodeJS.Timeout | null>(null)

  useEffect(() => {
    setIntervalRef(setInterval(() => {
      setNow(Date.now())
    }, 1000))

    return stopInterval
  }, [])

  const stopInterval = () => {
    if(!interval) { return }
    clearInterval(interval)
  }

  const style = () => {
    const { hue, saturation, lightness } = calculatedColour(item.lastClicked, now, item.cooldown);

    return {
      backgroundColor: `hsl(${hue}, ${saturation}%, ${lightness}%)`,
      color: contrastColour({hue, saturation, lightness})
    }
  }

  const updateTime = (e:MouseEvent) => {
    if(e.button !== 0) { return }

    setItems(resetClickTime(items, id))
  }

  const editItem = (e:MouseEvent) => {
    e.preventDefault();

    setUI({ editingID: id })
  }

  const easterEgg = (e:MouseEvent) => {
    if(e.button !== 1) { return }

    const heckstring = ["heck", "hecky!", "bark!", "barkbarkbark!"]

    setHeckstring(heckstring[Math.floor(Math.random() * heckstring.length)])
    setHeck(true)
  }

  return (
    <>
      <IonButton
        onClick={updateTime}
        onAuxClick={easterEgg}
        onContextMenu={editItem}
        className="item-leaf"
        style={style()}
      >
        <div className="button-container">
          <h2>{item.name}</h2>
          <p>{humanisedDateDifference(item.lastClicked, now)} ago</p>
        </div>
      </IonButton>
      <IonPopover reference="trigger" dismissOnSelect={true} side="bottom" size="auto" isOpen={heck} onDidDismiss={() => setHeck(false)}>
        <IonContent className="ion-padding">{heckstring}</IonContent>
      </IonPopover>
    </>
  )
}

export default ItemLeaf;