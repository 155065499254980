import { useRef } from "react"
import { IonHeader } from "@ionic/react"
import { useItems } from "@hooks/ItemProvider"
import { v4 as uuidv4 } from "uuid"
import { Item } from "@definitions/items"
import ItemForm from "@components/molecules/ItemForm"
import AuthOptions from "@components/organisms/AuthOptions"

const NavigationBar = () => {
  const formRef = useRef<HTMLFormElement>(null);
  const { items, setItems } = useItems();

  const addItem = (item:Item) => {
    setItems({...items, [uuidv4()]: item})

    const target = formRef.current
    if(!target) { return }

    target.reset()
  }

  return (
    <IonHeader>
      <section className="title-bar">
        <h1>Last Clicked...</h1>
        <AuthOptions />
      </section>
      <ItemForm
        sentenceBreaker="every"
        actionName="Add"
        submitAction={addItem}
        formRef={formRef}
        showLabels={false}
      />
    </IonHeader>
  );
}

export default NavigationBar;