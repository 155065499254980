import AuthModal from "@components/organisms/AuthModal"
import { register as registerAPI, login as loginAPI, signout as signoutAPI } from "@api/auth"
import { useState } from "react"
import { IonButton } from "@ionic/react"
import { APIResponse, AuthPayload } from "@definitions/api"
import { buildResponse } from "@api/config"
import { useAuth } from "@hooks/AuthProvider"
import { Auth } from "@definitions/auth"
import { isSignedIn } from "@utils/auth"

const AuthOptions = () => {
  const { auth, setAuth } = useAuth();
  const [ showSignUpModal, setShowSignUpModal ] = useState(false)
  const [ showSignInModal, setShowSignInModal ] = useState(false)

  const register = async (data:object):Promise<APIResponse> => {
    const { email, password } = data as AuthPayload;
    if(!email || !password) { return Promise.resolve(buildResponse({})) }

    const response = await registerAPI(email, password)
    if(response.success) {
      setShowSignUpModal(false)
      setAuth(response.payload.data as Auth)
    }

    return response
  }

  const login = async (data:object) => {
    const { email, password } = data as AuthPayload;
    if(!email || !password) { return Promise.resolve(buildResponse({})) }

    const response = await loginAPI(email, password)
    if(response.success) {
      setShowSignInModal(false)
      setAuth(response.payload.data as Auth)
    }

    return response
  }

  const signout = async () => {
    const { success } = await signoutAPI(auth)
    if(success) { setAuth({ token: "", expiryTime: 0 }) }
  }

  if (isSignedIn(auth)) {
    return (
      <section className="action-container">
        <IonButton color="secondary" onClick={signout}>Sign out</IonButton>
      </section>
    )
  }

  if (true) {
    console.log("We don't support cloud saves yet")
    return <></>
  }

  return (
    <>
      <section className="action-container">
        <IonButton color="light" fill="outline" onClick={() => setShowSignUpModal(true)}>Sign Up</IonButton>
        <IonButton color="secondary" onClick={() => setShowSignInModal(true)}>Sign In</IonButton>
      </section>
      <AuthModal
        isOpen={showSignUpModal}
        actionName="Register"
        onClose={() => { setShowSignUpModal(false) }}
        title="Register"
        submit={register}
      >
        <section>
          <h2>Hey there!</h2>
          <p>You <strong>don't need to sign up</strong> to use Last Clicked. If you want to keep using Last Clicked here in your browser, just close this form and keep going. 😃</p>
          <p>However, if you want to be able to access your items from anywhere, create an account below! We won't send you any emails unless you opt in to announcements -- it's just so that you can reset your password if you forget it.</p>
          <p>(Of course, if you don't trust that, nothing stops you from signing up with a throwaway email. I'm an unendorsed fan of sharklasers, personally.)</p>
        </section>
        <h2>Register</h2>
      </AuthModal>
      <AuthModal
        isOpen={showSignInModal}
        actionName="Sign in"
        onClose={() => { setShowSignInModal(false) }}
        title="Sign in"
        submit={login}
      >
        <h2>Welcome back ✨</h2>
      </AuthModal>
    </>
  )
}

export default AuthOptions;