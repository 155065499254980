import { createContext, useContext, useEffect, useState } from "react";
import { Items } from "@definitions/items";

type ItemContextType = {
  items: Items,
  setItems: (items:Items) => void,
}

const ItemContext = createContext({
  items: {},
  setItems: (items:Items) => {}
});

export const useItems = ():ItemContextType => {
  const { items, setItems } = useContext(ItemContext);

  return { items, setItems };
}

const ItemProvider = ({ children }: any) => {
  const [ items, setItems ] = useState<Items>({});
  const [ mounting, setMounting ] = useState(true);

  useEffect(() => {
    try {
      setMounting(false);
      const storedItems = localStorage.getItem('items');

      if (storedItems) {
        setItems(JSON.parse(storedItems));
        return
      }
    } catch {
      console.error("Bad state. Clearing localStorage. Previous data: ", localStorage.getItem("items"));
      localStorage.setItem("items", "{}");
    }
  }, [])

  useEffect(() => {
    // Don't let the initial mount trigger a save as it clobbers saved data.
    if (mounting) { return; }

    localStorage.setItem('items', JSON.stringify(items));
  }, [items])

  return (
    <ItemContext.Provider value={{ items, setItems }}>
      {children}
    </ItemContext.Provider>
  );
}

export default ItemProvider;