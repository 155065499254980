import "@ionic/react/css/core.css";
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

import "./index.css"

import { IonApp, IonPage } from '@ionic/react';
import NavigationBar from './components/molecules/NavigationBar';
import ItemProvider from "@hooks/ItemProvider";
import ItemList from "@components/molecules/ItemList";
import OverlayActions from "@components/organisms/OverlayActions";
import UIProvider from "@hooks/UIProvider";
import Footer from "@components/atoms/Footer";
import AuthProvider from "@hooks/AuthProvider";

const App = () => {
  return (
    <AuthProvider> {/* Auth is used for all synchronisation operations and must be parent. */}
      <UIProvider>
        <ItemProvider>
          <IonApp>
            <IonPage>
              <NavigationBar />
              <ItemList />
              <OverlayActions />
              <Footer />
            </IonPage>
          </IonApp>
        </ItemProvider>
      </UIProvider>
    </AuthProvider>
  );
}

export default App;
