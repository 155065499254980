import { IonButton } from '@ionic/react';
import { useState } from 'react';
import { useItems } from "@hooks/ItemProvider";

type Props = { id: string, name: string }

const ItemDeletionSection = ({ id, name }:Props) => {
  const { items, setItems } = useItems();
  const [ warn, setWarn ] = useState(false)

  const deleteItem = () => {
    const { [id]: _, ...newItems } = items
    setItems(newItems)
  }

  const renderWarning = () => {
    if(!warn) return null;

    return (
      <>
        <p>Are you sure you want to delete "{name}"?</p>
        <IonButton
          color="danger"
          expand="block"
          onClick={deleteItem}
        >
          Confirm
        </IonButton>
        <IonButton
          expand="block"
          fill="outline"
          onClick={() => setWarn(false)}
        >
          Cancel
        </IonButton>
      </>
    )
  }

  return (
    <>
    <IonButton
      color="danger"
      expand="block"
      onClick={() => setWarn(true) }
    >
      Delete
    </IonButton>
    { renderWarning() }
    </>
  )
}

export default ItemDeletionSection;