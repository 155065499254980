import { useUI } from "@hooks/UIProvider";
import { useItems } from "@hooks/ItemProvider";
import ItemEditModal from "./ItemEditModal";
import { useEffect, useState } from "react";
import { Item } from "@definitions/items";
import { updateItem } from "@utils/state";

const OverlayActions = () => {
  const { ui, setUI } = useUI();
  const { items, setItems } = useItems();
  const [ editingItem, setEditingItem ] = useState<Item|null>(null);
  const { editingID }  = ui;

  useEffect(() => {
    if(!editingID) return;

    const item = items[editingID];
    setEditingItem(item);

    if(!item) {
      setUI({ editingID: null });
    }
  }, [items, editingID, setUI]);

  if(!editingID) return null;

  const editItem = (newItem:Item) => {
    setItems(updateItem(items, editingID, newItem))
    setUI({ editingID: null });
  }

  return (
    <>
      <ItemEditModal
        isOpen={ui.editingID !== null}
        closeAction={() => setUI({ editingID: null })}
        id={editingID}
        updateAction={editItem}
      />
    </>
  );
}

export default OverlayActions;
