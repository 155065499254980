import { baseURL, buildResponse, baseHeaders as headers } from '@api/config'
import { APIResponse } from '@definitions/api'
import { Auth } from '@definitions/auth'
import { decodeJWT } from '@utils/auth'

export const register = async (email:string, password:string):Promise<APIResponse> => {
  return authorize(email, password, 'signup', 201)
}

export const login = async (email:string, password:string):Promise<APIResponse> => {
  return authorize(email, password, 'login', 200)
}

const authorize = async (email:string, password:string, endpoint:string, successCode:number):Promise<APIResponse> => {
  try {
    const response = await fetch(`${baseURL}/${endpoint}`, { method: "POST", headers, body: JSON.stringify({ user: { email, password } }) })
    if(response.status !== successCode) {
      const { errors } = await response.json()
      return buildResponse({ success: false, errors })
    }

    const token = response.headers.get('Authorization') || ""
    const expiryTime = (decodeJWT(token).exp || 0) * 1000

    return buildResponse({ success: true, data: { token, expiryTime } })
  } catch (error) {
    // @ts-expect-error TS still doesn't support casting error to Error
    return buildResponse({ success: false, errors: { network: error.message } })
  }
}

export const signout = async (auth:Auth):Promise<APIResponse> => {
  const response = await fetch(`${baseURL}/logout`, { method: "DELETE", headers })
  if (response.status !== 200 && response.status !== 401) {
    const { errors } = await response.json()
    return buildResponse({ success: false, errors })
  }

  return buildResponse({ success: true })
}