import { Colour } from "@definitions/items";

export const ONE_MINUTE = 60
export const ONE_HOUR = ONE_MINUTE * 60;
export const ONE_DAY = ONE_HOUR * 24;
export const ONE_WEEK = ONE_DAY * 7;
export const ONE_YEAR = ONE_WEEK * 52.1429; // Welcome to hell.

export type TimeUnit = keyof typeof timeUnit;
type TimeValue = typeof timeUnit[TimeUnit];

export const timeUnit = {
  minutes: ONE_MINUTE,
  hours: ONE_HOUR,
  days: ONE_DAY,
  weeks: ONE_WEEK,
  years: ONE_YEAR,
}

export const TERMINAL_HUE = 0;

const formatDate = (units:number, unitName:string):string => {
  return `${Math.round(units)} ${unitName}${units === 1 ? '' : 's'}`
};

// TODO: Refactor to use decomposedCooldown
export const humanisedDate = (seconds:number):string => {
  if (seconds < ONE_MINUTE) { return formatDate(seconds, "second") }

  const { amount, unitName } = decomposedCooldown(seconds);
  return formatDate(amount, unitName);
}

export const decomposedCooldown = (seconds:number):{ amount:number, unit:TimeValue, unitName:string} => {
  if (seconds < ONE_MINUTE) { return { amount: seconds/ONE_MINUTE, unit: ONE_MINUTE, unitName: "minute" } }
  if (seconds < ONE_HOUR) { return { amount: seconds/ONE_MINUTE, unit: ONE_MINUTE, unitName: "minute" }; }
  if (seconds < ONE_DAY) { return { amount: seconds/ONE_HOUR, unit: ONE_HOUR, unitName: "hour" }; }
  if (seconds < ONE_WEEK) { return { amount: seconds/ONE_DAY, unit: ONE_DAY, unitName: "day" }; }
  if (seconds < ONE_YEAR) { return { amount: seconds/ONE_WEEK, unit: ONE_WEEK, unitName: "week" }; }

  return { amount: seconds/ONE_YEAR, unit: ONE_YEAR, unitName: "year"};
}

export const humanisedDateDifference = (dateFrom:number, dateTo:number):string => {
  const secondDifference = Math.round((dateTo - dateFrom) / 1000);

  return humanisedDate(secondDifference);
}

export const calculatedColour = (dateFrom:number, dateTo:number, cooldown:number):Colour => {
  const secondDifference = Math.round((dateTo - dateFrom) / 1000);
  const maxHue = 116; // Green
  const minHue = 0; // Red

  const unitTime = maxHue/(cooldown * 2); // Cooldown * 2 so that yellow is "do now" and red is "agh crap"
  const hue = Math.min(Math.max(maxHue - (secondDifference * unitTime), minHue), maxHue);
  return {
    hue,
    saturation: 58,
    lightness: 44,
  }
}

export const contrastColour = (backgroundColor:Colour):string => {
  // return backgroundColor.lightness < 123 ? "#ffffff" : "#000000";
  return "#000000";
}