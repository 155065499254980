import { createContext, useContext, useEffect, useState } from "react";
import { Auth } from "@definitions/auth";

type AuthContextType = {
  auth: Auth,
  setAuth: (auth:Auth) => void,
}

const AuthContext = createContext({
  auth: { },
  setAuth: (auth:Auth) => {}
});

export const useAuth = ():AuthContextType => {
  const { auth, setAuth } = useContext(AuthContext);

  return { auth, setAuth };
}

const AuthProvider = ({ children }: any) => {
  const [ auth, setAuth ] = useState<Auth>({});
  const [ mounting, setMounting ] = useState(true);

  useEffect(() => {
    const storedAuth = localStorage.getItem('auth');

    if (storedAuth) {
      const parsedAuth = JSON.parse(storedAuth)
      setAuth(parsedAuth);
      if(!!parsedAuth.expiryTime) {
        setTimeout(unsetAuth, (parsedAuth.expiryTime - Date.now()))
      }
    }

    setMounting(false);
  }, [])

  const unsetAuth = () => {
    setAuth({});
  }

  useEffect(() => {
    // Don't let the initial mount trigger a save as it clobbers saved data.
    if (mounting) { return; }
    localStorage.setItem('auth', JSON.stringify(auth));
    if(!!auth && !!auth.expiryTime) {
      setTimeout(unsetAuth, auth.expiryTime - Date.now())
    }
  }, [auth])

  return (
    <AuthContext.Provider value={{ auth, setAuth }}>
      {children}
    </AuthContext.Provider>
  );
}

export default AuthProvider;