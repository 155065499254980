import ItemLeaf from '@components/molecules/ItemLeaf';
import { useItems } from '@hooks/ItemProvider';
import React from 'react';

const ItemList:React.FunctionComponent = () => {
  const { items } = useItems();

  const itemElements = () => {
    if(!items) { return null; }

    return Object.keys(items).map((id:string) => (
        <ItemLeaf id={id} key={items[id].lastClicked} item={items[id]} />
      ));
  }

  return (
    <section className="item-list">
      {itemElements()}
    </section>
  );
};

export default ItemList;