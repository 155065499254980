import ItemForm from "@components/molecules/ItemForm";
import { Item } from "@definitions/items";
import { IonModal, IonHeader, IonToolbar, IonButtons, IonButton, IonTitle, IonContent } from "@ionic/react";
import { humanisedDate } from "@utils/date";
import { useItems } from "@hooks/ItemProvider";
import ItemDeletionSection from "@components/molecules/ItemDeletionSection";

type Props = {
  isOpen: boolean
  closeAction: () => void
  id: string
  updateAction: (item:Item) => void
}

const ItemEditModal = ({isOpen, closeAction, id, updateAction}:Props) => {
  const { items } = useItems();
  const item = items[id] || null
  const placeholder = "A task; a visit; a friend. Anything you want to see or do intermittently."

  if(!item) return null;

  return (
    <IonModal isOpen={isOpen} onWillDismiss={closeAction}>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Editing {item.name}</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={closeAction}>Cancel</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <ItemForm
          sentenceBreaker={`Currently decays over ${humanisedDate(item.cooldown)}.  New cooldown:`}
          actionName="Update"
          className="column-form"
          submitAction={updateAction}
          showLabels={true}
          id={id}
          taskPlaceholder={placeholder}
        />
        <ItemDeletionSection id={id} name={item.name} />
      </IonContent>
    </IonModal>
  )
}

export default ItemEditModal;