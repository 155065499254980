import { Auth } from "@definitions/auth";

export const decodeJWT = (token: string) => {
  try {
    const [_header, payload, _signature] = token.split(".");
    const decodedPayload = atob(payload);

    return JSON.parse(decodedPayload);
  } catch {
    return {};
  }
}

export const isSignedIn = (auth:Auth):boolean => {
  // With auth being unset by the provider on expiry, we can just check for the presence of the token and expiryTime.
  return !!auth.token && !!auth.expiryTime;
}